import React from 'react';
import '../index.css'

function Loadscreen() {


    function animationDone() {
        console.log("done")
        setTimeout(() => {
            document.querySelector('.loadscreen').style.marginTop = "-100vh"
        }, 500);
    }



    return <div className='loadscreen'>
        <div className='loadscreen--textContainer'>
            <div className='loadscreen--text'>
                <div>m</div>
                <div className='loadscreen--text2'>usch</div>
                <div onAnimationEnd={animationDone} className='loadscreen--text3'>-</div>
                <div className='loadscreen--text4'>eum</div>
            </div>
        </div>
    </div>;
}

export default Loadscreen;
