import React from 'react'


function ProjectTile(props) {
    //console.log(props)
    const { coverFoto, projectnaam } = props.info
    return (
        <div className='projectTile'>
            <img alt='' className='projectTile__image' src={coverFoto.url} />
            <div className='projectTile__text__box'>
                <h1 className='projectTile__text'>{projectnaam}</h1>
            </div>
        </div>
    )
}

export default ProjectTile