import React from 'react'
import fbIcon from '../images/fbIcon_white.svg'
import instaIcon from '../images/instaIcon_white.svg'
import contactImg from '../images_edited/contactImg.png'
function Contact() {
    return (
        <div className='contactPage'>
            <img alt='' src={contactImg} />
            <div className='contactPage--infoContainer'>


                <div className='contactPage--info'>
                    <p>Terrest 3, 1670 Heikruis</p>
                    <p>+32 (0)2 308 97 16</p>
                    <p>info@musch-eum.be</p>
                    <p>BE 0884.457.975</p>
                </div>
                <div className='contactPage--iconsContainer'>
                    <p>Volg ons op sociale media</p>
                    <div className='contactPage--icons'>
                        <a href='https://www.facebook.com/MUSCHEUM' target="_blank" rel='noreferrer'><img alt='' src={fbIcon} /></a>
                        <a href='https://www.instagram.com/musch_eum/' target="_blank" rel='noreferrer'><img alt='' src={instaIcon} /></a>
                    </div>

                </div>
            </div>



            <div className='contactPage--map'>
                <iframe className='contactPage--map--iframe' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.8611290589056!2d4.078338516325287!3d50.72251547560376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3b75d24c4d97b%3A0x72615ff740f08b80!2sArchitectural%20Design%20MUSCH-EUM%20bv!5e0!3m2!1snl!2sbe!4v1650528886089!5m2!1snl!2sbe" loading="lazy" ></iframe>

            </div>
        </div>
    )
}

export default Contact