export default [
    {
        nummer: "1",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [1, 1, 1, 1],
        categorie: "School/Openbaar"
    },
    {
        nummer: "2",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [],
        categorie: "School/Openbaar"
    },
    {
        nummer: "3",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [],
        categorie: "School/Openbaar"
    },
    {
        nummer: "4",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [],
        categorie: "School/Openbaar"
    },
    {
        nummer: "5",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [],
        categorie: "School/Openbaar"
    },
    {
        nummer: "6",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [],
        categorie: "School/Openbaar"
    },
    {
        nummer: "7",
        naam: "Dorpschool Kester",
        text: "de dorpsschool in kester he",
        plaats: "Kester",
        coordinaten: { long: 10, lat: 10 },
        fotos: [],
        categorie: "School/Openbaar"
    },
]