import React from 'react'
import ProjectTile from './ProjectTile'
import { Link } from "react-router-dom";
import Footer from './Footer';
import { request } from 'graphql-request';


function Projects() {

    const [projects, setProjects] = React.useState([]);
    const [filterCat, setFilterCat] = React.useState('alles');


    React.useEffect(() => {
        const fetchProjects = async () => {
            const { projects } = await request(
                'https://api-eu-west-2.graphcms.com/v2/ckzzx0bo98n2501z86us8aefi/master',
                `
                {
                    projects{
                      projectnaam
                      nummer
                      coverFoto {
                        url
                      }
                      categorie
                    }
                  }
                `
            );

            setProjects(projects);
        };

        fetchProjects();
    }, []);
    //console.log(projects)

    const projectTileElems = projects.map(project => {
        if (filterCat === 'alles' || filterCat === project.categorie.toLowerCase()) {
            return (
                <Link to={`/projecten/${project.nummer}`} key={project.nummer}>
                    <ProjectTile info={project} />
                </Link>
            )
        } else {
            return ''
        }

    })

    const categories = ["alles", "woningen", "verbouwingen", "appartementen", "commercieel", "openbaar"]
    const categoriesElems = categories.map((val, idx) => {
        return (
            <button key={idx} onClick={applyFilter} value={val} className={filterCat === val ? "projectsPage__filter__selected" : ''}>  {val}</button>
        )
    })

    function applyFilter(e) {
        setFilterCat(e.target.value)
    }


    return (
        <>
            <div className='projectsPage'>
                <div className='projectsPage__filter'>
                    {categoriesElems}
                </div>
                <div className='projects'>
                    {projectTileElems}
                </div>
                <Footer />
            </div></>

    )
}

export default Projects