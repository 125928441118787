import React from 'react';
import image1 from "../images/school.png"
import image_1 from "../images_edited/image_main_1.jpg"
import image_2 from "../images_edited/image_main_2.jpg"
import image_3 from "../images_edited/image_main_3.jpg"
import image_4 from "../images_edited/image_main_4.jpg"
import Footer from './Footer';
import { Link } from "react-router-dom"

function Mainpage(props) {

    return (
        <div className='mainpage'>


            <h1 className='mainpage--slogan'>Your needs + Our ideas <br />
                =
                <br />
                Your space!
            </h1>

            <div className='mainpage--section mainpage--section__off'>

                <div className='mainpage--sectionImageBox'>
                    <img alt='' className='mainpage--sectionImage' src={image_1} />
                    <div className='mainpage--parallaxBox pBoxLeftImg'></div>
                </div>

                <div className='mainpage--sectionText'>
                    <h2>Onze filosofie
                    </h2>
                    <br />
                    <br />
                    <p>Een woning is niet zomaar vier muren om je heen. Het is een derde huid, waar je je thuis moet voelen, en waar je voldoende nieuwe prikkels moet krijgen. Het is een plek waar je je geborgen voelt en tot rust kan komen maar ook een plek waar je voortdurend op ontdekking kan gaan, waar je inspiratie kan opdoen. Eigenlijk verdient ieder zijn eigen mus(ch-)eum om tot rust te komen en op ontdekking te gaan.</p>
                    <div className='mainpage--parallaxBox pBoxRightText'></div>
                </div>

            </div>



            <div className='mainpage--section'>
                <div className='mainpage--sectionText'>
                    <h2>Architecturaal concept
                    </h2>
                    <br />
                    <br />
                    <p>Deze filosofie wordt omgezet in een moderne architectuur met compacte en strakke sculpturale uitgesproken volumes; meestal met een combinatie van verschillende kleuren en/of gevelmaterialen. Zo creëren we een speels karakter en geven we de woning een meerwaarde.
                        Die vormgeving wordt ook doorgetrokken in het interieur waar gestreefd wordt naar een sterke ruimtelijkheid binnen een compacte en een praktische organisatie en dit in samenspel met de uitstraling van het buitenvolume.
                    </p>
                    <div className='mainpage--parallaxBox pBoxLeftText'></div>
                </div>
                <div className='mainpage--sectionImageBox'>
                    <img alt='' className='mainpage--sectionImage' src={image_2} />
                    <div className='mainpage--parallaxBox pBoxRightImg'></div>
                </div>
            </div>


            <div className='mainpage--section mainpage--section__off'>

                <div className='mainpage--sectionImageBox'>
                    <img alt='' className='mainpage--sectionImage' src={image_3} />
                    <div className='mainpage--parallaxBox pBoxLeftImg'></div>
                </div>

                <div className='mainpage--sectionText'>
                    <h2>Werkwijze
                    </h2>
                    <br />
                    <br />
                    <p>Eerst en vooral wordt er geluisterd naar de noden van de bouwheer, deze worden dan in een eerste schetsontwerp gegoten met aandacht voor:</p>
                    <br />
                    <br />
                    <ul className='mainpage__list--dots'>
                        <li>alle noden van de klant</li>
                        <li>de oriëntatie gebouw op het terrein</li>
                        <li>de mogelijkheden en de opportuniteiten van terrein en omgeving</li>
                        <li>de beleving in en rondom de woning</li>
                        <li>ecologische aspecten (waar mogelijk)</li>
                        <li>energetisch resultaat (van BEN-woning tot passief)</li>
                    </ul>
                    <br />
                    <br />
                    <p>Zodra de noden van de klant en onze ideeën op dezelfde golflengte zitten, wordt het project verder uitgewerkt en begeleiden we het project van begin tot einde.
                    </p>
                    <div className='mainpage--parallaxBox pBoxRightText'></div>
                </div>

            </div>

            <div className='mainpage--section'>



                <div className='mainpage--sectionText'>
                    <h2>Projecten
                    </h2>
                    <p>Ontdek onze projecten hier</p>
                    <Link className='mainpage__projectsLink' to="/projecten/">
                        <p>Projecten</p>
                    </Link>
                    <div className='mainpage--parallaxBox pBoxLeftText'></div>
                </div>
                <div className='mainpage--sectionImageBox'>
                    <img alt='' className='mainpage--sectionImage' src={image_4} />
                    <div className='mainpage--parallaxBox pBoxRightImg'></div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Mainpage;
