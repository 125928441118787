import React from 'react';
import logoImg from '../images/logo.svg'
import { Link } from "react-router-dom"

function Header(props) {

    //console.log(props)




    return (
        <div className='header'>
            <div className='header--container'>
                <Link to="/" className='header__logo'>
                    <div href='#' className='header__logo'><img alt='' src={logoImg} /></div>
                </Link>


                {/*  <a href='#'><h2 className='header--item'>Over</h2></a> */}
                <Link to="/projecten/">
                    <h2 className='header--item'>Projecten</h2>
                </Link>

                <Link to="/contact/">
                    <h2 className='header--item'>Contact</h2>
                </Link>
            </div>
        </div >
    );
}

export default Header;
