import React from 'react';
import fbIcon from '../images/fbIcon.svg'
import instaIcon from '../images/instaIcon.svg'

function Footer() {
    return (
        <div name="footer" className='footer'>
            <div className='footer--icons'>
                <a href='https://www.facebook.com/MUSCHEUM' target="_blank" rel='noreferrer'><img alt='' src={fbIcon} /></a>
                <a href='https://www.instagram.com/musch_eum/' target="_blank" rel='noreferrer'><img alt='' src={instaIcon} /></a>
            </div>
            <div className='footer--texts'>
                <h3>Terrest 3, 1670 Heikruis</h3>
                <h3>+32 (0)2 308 97 16</h3>
                <h3>info@musch-eum.be</h3>
                <h3>BE 0884.457.975</h3>
            </div>
        </div>
    );
}

export default Footer;
